jQuery(function ($) {
  let scrollTop;

  // Add shrink class to site header after 50px

  $(document).on("scroll", () => {
    scrollTop = $(window).scrollTop();

    if (scrollTop >= 100) {
      $(".utility-bar").addClass("hidden");
      $(".site-header").addClass("nav-scroll-top");
    } else {
      $(".utility-bar").removeClass("hidden");
      $(".site-header").removeClass("nav-scroll-top");
    }
    // if (
    //   scrollTop > 800 &&
    //   scrollTop < $(document).height() - $(window).height() - 800
    // ) {
    //   $(".site-header").addClass("visible");
    // } else {
    //   $(".site-header").removeClass("visible");
    // }
    // if (scrollTop > 0) {
    //   $(".home .site-header").addClass("bg-white shrink");
    //   $(".site-header.big").removeClass("big");
    // } else {
    //   $(".home .site-header").removeClass("bg-white");
    //   $(".home .site-header").addClass("big");
    // }
  });
  $(".menu-toggle").on("click", function () {
    if ($(this).hasClass("activated")) {
      $("html").addClass("overflow-hidden");
    } else {
      $("html").removeClass("overflow-hidden");
    }
  });

  $(window).resize(() => {
    if (window.innerWidth >= 992) {
      $("html").removeClass("overflow-hidden");
    }
  });
});
