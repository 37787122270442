import autosize from "autosize/dist/autosize.min.js";

export default {
  init() {
    // JavaScript to be fired on all pages

    // add passive event listener to touch and scroll events as suggested by https://web.dev/
    // document.addEventListener("touchstart", onTouchStart, { passive: true });
    // document.addEventListener("wheel", onTouchStart, { passive: true });
    // document.addEventListener("mousewheel", onTouchStart, { passive: true });
    // document.addEventListener("touchmove", onTouchStart, { passive: true });

    // submit button loading animation
    $(".wpcf7-submit").on("click", function () {
      $(this).addClass("loading");
    });
    $(".wpcf7-submit").on("click", function () {
      $(this).addClass("loading");
    });

    $(".wpcf7").on(
      "wpcf7invalid wpcf7spam wpcf7mailsent wpcf7mailfailed",
      function () {
        $(this).find(".wpcf7-submit").removeClass("loading");
      }
    );
    // minimalist autoresizable textareas
    autosize($("textarea"));
    $("#share").jsSocials({
      showLabel: false,
      showCount: false,
      shares: ["facebook", "twitter", "linkedin", "pinterest"],
    });

    $("#contact-select").change(function () {
      const selectContact = $(this).children("option:selected").val();
      $(`.${selectContact}`).show();
      const notSelectContact = $(this).children("option:not(:selected)");
      // $("." + notSelectContact).hide();
      $(notSelectContact).each(function () {
        $(`.${$(this).val()}`).hide();
      });
    });
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
