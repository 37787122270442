import Barba from "barba.js/dist/barba";

export default function barbaInit(routes) {
	Barba.Pjax.Dom.containerClass = "pjax-container";
	Barba.Pjax.Dom.wrapperId = "pjax-wrapper";
	let spinnerTimeOut;
	// Create a global variable to store the bodyClasses
	let bodyClasses;
	// Blacklist all WordPress Links (e.g. for adminbar)
	function addBlacklistClass() {
		$("a").each(function () {
			if (
				this.href.indexOf("/wp-admin/") !== -1 ||
				this.href.indexOf("/wp-login.php") !== -1
			) {
				$(this).addClass("no-barba").addClass("wp-link");
			}
		});
	}

	// Set blacklist links
	addBlacklistClass();

	// Fire Barba.js
	Barba.Pjax.start();
	Barba.Prefetch.init();

	Barba.Dispatcher.on(
		"newPageReady",
		(currentStatus, prevStatus, container, newPageRawHTML) => {
			const path = currentStatus.url
				.split(window.location.origin)[1]
				.substring(1); // get path of current page
			// "notbody" see: https://github.com/luruke/barba.js/issues/51#issuecomment-241417316
			const response = newPageRawHTML.replace(
				/(<\/?)body( .+?)?>/gi,
				"$1notbody$2>",
				newPageRawHTML
			);
			bodyClasses = $(response).filter("notbody").attr("class");
			// $("body").attr("class", bodyClasses);
			$(".menu-item").removeClass("active");
			$(`a[href$="${path}"]`).parent(".menu-item").addClass("active"); // add CSS class to current .navigation__link
			$(".site-header").removeClass("big");
			if ($(response).hasClass("home")) {
				$(".site-header").addClass("big").removeClass("bg-white shadow-sm");
			} else {
				$(".site-header").addClass("bg-white");
			}
			if (window.ga && ga.create) {
				ga("set", "page", window.location.pathname);
				ga("send", "pageview");
			}
		}
	);

	Barba.Dispatcher.on("transitionCompleted", () => {
		// Set new classes from #af-classes to body
		// $("body").attr("class", $("#body-classes").attr("class"));

		if (typeof lozadInit === "function") {
			lozadInit();
		}
		if (typeof yall === "function") {
			yall({
				observeChanges: true,
				idlyLoad: true,
				threshold: 400,
			});
		}

		// loadPageAnimations();
		// Fire routes again after new content loaded
		routes.loadEvents();
	});

	const FadeTransition = Barba.BaseTransition.extend({
		start() {
			/**
			 * This function is automatically called as soon the Transition starts
			 * this.newContainerLoading is a Promise for the loading of the new container
			 * (Barba.js also comes with an handy Promise polyfill!)
			 */

			if ($(".menu-toggle").hasClass("activated")) {
				$(".menu-toggle").click();
			}
			spinnerTimeOut = setTimeout(() => {
				$("#logo-anim").fadeIn();
			}, 500);
			// As soon the loading is finished and the old page is faded out, let's fade the new page
			Promise.all([this.newContainerLoading, this.fadeOut()]).then(
				this.fadeIn.bind(this)
			);
		},

		fadeOut() {
			/**
			 * this.oldContainer is the HTMLElement of the old Container
			 */

			return $(this.oldContainer).animate({ opacity: 0 }).promise();
		},

		fadeIn() {
			/**
			 * this.newContainer is the HTMLElement of the new Container
			 * At this stage newContainer is on the DOM (inside our #barba-container and with visibility: hidden)
			 * Please note, newContainer is available just after newContainerLoading is resolved!
			 */

			const _this = this;
			const $el = $(this.newContainer);

			$(this.oldContainer).hide();

			$el.css({
				visibility: "visible",
				opacity: 0,
			});
			// Apply the classes to the current body
			$("body").attr("class", bodyClasses);
			$(window).scrollTop(0);
			clearTimeout(spinnerTimeOut);
			$("#logo-anim").fadeOut();
			// addthis.layers.refresh();
			$el.animate({ opacity: 1 }, 400, () => {
				/**
				 * Do not forget to call .done() as soon your transition is finished!
				 * .done() will automatically remove from the DOM the old Container
				 */

				_this.done();
			});
		},
	});

	/**
	 * Next step, you have to tell Barba to use the new Transition
	 */

	Barba.Pjax.getTransition = function () {
		/**
		 * Here you can use your own logic!
		 * For example you can use different Transition based on the current page or link...
		 */

		return FadeTransition;
	};
}
